import React from "react";
import { Link } from "gatsby";
import remark from "remark";
import remarkHtml from "remark-html";
import sanitize from "rehype-sanitize";

import Layout from "../components/layout";

import MainTable from "../components/table";
import TopHeader from "../components/topheader";
import websiteData from "../data/setup.json";

const setupData = websiteData.items[0].fields;
const gh = require("hast-util-sanitize/lib/github");

var description = remark()
  .use(remarkHtml)
  .processSync(setupData.descriptionBeginningOfWebsite)
  .toString();

const newLocal = 0;
const IndexPage = (props) => {
  const { location } = props;
  const pathname = location.href;
  var subdomain = String(location.hostname).split(".");
  var hostn;
  if (subdomain[0] == "localhost") hostn = "yourspv";
  else hostn = subdomain[0];

  return (
    <Layout>
      <TopHeader client={hostn}></TopHeader>
      <br /> <br />
      <div  style={{
                   maxWidth: 1024,
                    margin: `0 auto`,
                    color: `#333`,
                   
                 
        }} className="p-card-content p-shadow-2">
        <MainTable client={hostn}></MainTable>
   
      </div>
      <br /> <br />
      <footer style={{
          marginTop: `0rem`,
          maxWidth: 1024,
          margin: `0 auto`,
        }}>
          © {new Date().getFullYear()}, proudly served by 
          {' '}<a href="/">publicdocs.Info</a>
          
  


        </footer>
    </Layout>
  );
};
export default IndexPage;
