import React, { Component } from "react"
import { useState, useEffect, useRef } from "react"
import remark from 'remark';
import remarkHtml from 'remark-html';
import sanitize from 'rehype-sanitize';
import "./layout.css";

export default function TopHeader({ client }) {
  const [shownTitle, setTitle] = useState(null)
  const [shownDescription, setDescription] = useState(null)
  const [shownAnnouncement, setAnnouncment] = useState(null)
  const [primaryColour, setPrimaryColour] = useState(null)
  const dt = useRef(null)
  

 useEffect(()=>{

  fetch('/data/setup.json',{"method":"GET"})
      .then(response => response.json())
      .then(res => {
          setTitle(res.websiteHeader);
          setDescription(res.descriptionBeginningOfWebsite);
          setAnnouncment(res.topAnnouncement);
          setPrimaryColour(res.primaryColour);
        })
      .catch(console.log)
 }, []); 


  return (
    <div>
    <div className="text-announce" style={{
      background: primaryColour,
      
      margin: `0 auto`,
      color: `#333`,
     
    }} 
    
    
    dangerouslySetInnerHTML={{ __html:
            
        remark().use(remarkHtml)
            .processSync(shownAnnouncement).toString() }} />
<br></br><br></br>
    <h1  style={{
         
         maxWidth: 1024,
         margin: `0 auto`,
            
       }}>{(shownTitle ? shownTitle : "Loading...")}</h1><br></br><br></br>
        <div 
        
        style={{
         
          maxWidth: 1024,
          margin: `0 auto`,
          color: `#333`,
         
        }} 
        
        dangerouslySetInnerHTML={{ __html:
            
            remark().use(remarkHtml)
                .processSync(shownDescription).toString() }} />
        
     </div>
  )
}
